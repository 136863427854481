const JSON_PATTERN = /^\{.*}$/;

export const parseEventMessage = data => {
  if (data && typeof data === 'string' && JSON_PATTERN.test(data)) {
    const message = JSON.parse(data);

    if (!message.eventName) return null;

    return message;
  }

  return null;
};

export const isValidOrigin = (origin = '') => {
  const HOME_BINA_URL_PATTERN =
    process.env.NODE_ENV !== 'production'
      ? /^http:\/\/(\w+\.)?localhost/
      : new RegExp(`^https://(\\w+\\.)?${window.location.host}/?$`);

  return HOME_BINA_URL_PATTERN.test(origin);
};
