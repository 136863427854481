import Router from 'next/router';
import { BRIDGE_NO_TOP_INSET_KEY, BRIDGE_NO_TOP_INSET_REGEXP } from '../config';

export const useNoTopInsert = pathname => {
  const parsedUrl = new URL(`${window.location.origin}${pathname}`);

  const isSafeArea =
    parsedUrl.searchParams.get(BRIDGE_NO_TOP_INSET_KEY) === 'false';

  if (BRIDGE_NO_TOP_INSET_REGEXP.some(rx => rx.test(pathname)) && !isSafeArea) {
    parsedUrl.searchParams.set(BRIDGE_NO_TOP_INSET_KEY, 'false');

    Router.replace(parsedUrl.toString());
  }
};
